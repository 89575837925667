<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-row>
        <v-col md="6" cols="12">
          <h2 class="mb-5 text-h6">BANK REPORT</h2>
        </v-col>
        <v-col v-if="isUserLevel" class="pa-0" cols="12" md="3">
          <v-dialog
            ref="dialogDatepick"
            v-model="date_modal"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="เลือก วันที่ออเดอร์"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date_modal = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.dialogDatepick.save(dates)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
          <div class="caption text-center red--text text--darken-4">**ให้เอายอดที่ได้มาลบกับยอดตั้งต้นเอง</div>
        </v-col>
        <v-col v-if="isUserLevel" class="pa-0" cols="12" md="3">
          <v-btn text class="mt-4 pl-0 ml-0" @click="getData(true)"
            ><v-icon>search</v-icon></v-btn
          >
          <v-btn text class="mt-4 pl-0 ml-0" @click="getData(false)"
            >all year</v-btn
          >
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12">
          <h3>บัญชี โอนเงิน</h3>
           <b>กรณี 1.</b> จำนวนเงินห้ามเกิน 2,000,000 และต้องไม่เกิน 3,000 ครั้ง :: 
           <b>Check Point</b> totalAmount > {{ limit.transferamount | currency("", 0) }} || totalCount > {{ limit.transfertcount | currency("", 0) }} ครั้ง ให้แจ้งเตือน
        </v-col>
    </v-row>

   <v-row>
    <v-col>
    <!-- Table section -->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        :loading="dataTableLoading"
        hide-default-footer
        disable-pagination
        disable-filtering
        disable-sort
        mobile-breakpoint="200"
      >
        <!-- table top section -->

        <template v-slot:item="{ item }">
          <tr>
            <td class="pt-3 pb-3">{{ item.id }}</td>
            <td>
                <v-icon :class="checkPointTransfer(item.totalAmount, item.totalCount)">warning</v-icon>
            </td>
            <td class="pt-3 pb-3">
              <!-- aspect-ratio="1" หมายถึ อัตราส่วนรูปภาพ 1:1  -- lazy-src="http://www.google.com/image"  หมายถึงรุปจำลองก่อนโหดลรูปจริง-->
              <v-img
                v-if="item.image"
                :src="item.image | imageUrl"
                max-height="50"
                max-width="50"
                aspect-ratio="1"
              />
            </td>
            <td class="pt-3 pb-3">
              {{ item.name }}<br />{{ item.account_name }}<br />{{
                item.account_no
              }}
            </td>
            <td class="pt-3 pb-3">{{ item.start_amount | currency(" ฿", 0) }}</td>
            <td class="pt-3 pb-3">{{ item.start_count | currency("", 0) }}</td>
            <td class="pt-3 pb-3">{{ item.remark }}</td>
            <td class="yellow lighten-3 pt-3 pb-3">
              <span :class="checkPointTransferAmount(item.totalAmount)">{{ item.totalAmount | currency(" ฿", 0) }}</span>
            </td>
            <td class="yellow lighten-4 pt-3 pb-3">
              <span :class="checkPointTransferCount(item.totalCount)">{{ item.totalCount | currency("", 0) }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    </v-col>
    </v-row>

     <v-row class="mt-5">
        
        <v-col cols="12">
            <h3>บัญชี COD</h3>
           <b>กรณี 2.</b> จำนวนยอดเงินเท่าไหร่ก็ได้ จำนวนครั้งต้องไม่เกิน 400 ครั้ง :: 
           <b>Check Point</b> totalCount > {{ limit.codcount }} ครั้ง ให้แจ้งเตือน
           <div>หมายเหตุ: ให้วิธีนับอ้างอิงจาก ฟีลใน order table: cod_refpaid จาก COD-RMxxxxxxxx ใน api LINK FLASH กรณี alpha หรือ อื่นๆ หากโอนวันเดียวกันควรตั้งชื่ออ้างอิงให้เหมือนกัน เพราะใช้คำสั่ง GROUP เช่น ALPPAID20200318 ตัวเลข 20200318 คือ ปีเดือนวัน ที่ได้รับโอน</div>
        </v-col>
    </v-row>

     <v-row>
        <v-col>
 <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataCOD"
        :loading="dataTableLoading2"
        hide-default-footer
        disable-pagination
      >
        <!-- table top section -->

        <template v-slot:item="{ item }">
          <tr>
            <td class="pt-3 pb-3">{{ item.id }}</td>
            <td>
                <v-icon :class="checkPointCOD(item.totalAmount, item.totalCount)">warning</v-icon>
            </td>
            <td class="pt-3 pb-3">
              <!-- aspect-ratio="1" หมายถึ อัตราส่วนรูปภาพ 1:1  -- lazy-src="http://www.google.com/image"  หมายถึงรุปจำลองก่อนโหดลรูปจริง-->
              <v-img
                v-if="item.image"
                :src="item.image | imageUrl"
                max-height="50"
                max-width="50"
                aspect-ratio="1"
              />
            </td>
            <td class="pt-3 pb-3">
              {{ item.name }}<br />{{ item.account_name }}<br />{{
                item.account_no
              }}
            </td>
            <td class="pt-3 pb-3">{{ item.start_amount | currency(" ฿", 0) }}</td>
            <td class="pt-3 pb-3">{{ item.start_count | currency("", 0) }}</td>
            <td class="pt-3 pb-3">{{ item.remark }}</td>
            <td class="yellow lighten-3 pt-3 pb-3">
              {{ item.totalAmount | currency(" ฿", 0) }}
            </td>
            <td class="yellow lighten-4 pt-3 pb-3">
              <span :class="checkPointCOD(item.totalAmount, item.totalCount)">{{ item.totalCount | currency("", 0) }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
        </v-col>
    </v-row>


  </v-container>
</template>

<script>
import api, {BANKLIMIT} from "@/services/api";
import { userLevel } from "@/components/mixins/userLevel";
import _ from "lodash";

export default {
  name: "BankReport",
  mixins: [userLevel],
  data() {
    return {
      limit: {
          transferamount: BANKLIMIT.TRANSFERAMOUNT,
          transfertcount: BANKLIMIT.TRANSFERCOUNT,
          codcount: BANKLIMIT.CODCOUNT
      },
      mDataArray: [],
      mDataCOD: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id"
        },
        { text: "Status", value: "status"},
        { text: "Logo", value: "image" },
        { text: "Name", value: "name" },
        { text: "Start Amount", value: "start_amount" },
        { text: "Start Count", value: "start_count" },
        { text: "Remark", value: "remark" },
        {
          text: "Total Amount",
          value: "total_amount",
          class: "yellow lighten-3"
        },
        {
          text: "Total Count",
          value: "total_count",
          class: "yellow lighten-4"
        }
      ],
      dataTableLoading: true,
      dataTableLoading2: true,
      dates: ["", ""],
      date_modal: false,
      date_period: '',
    };
  },
  
  components: {
    // register ตัว components ที่เราทำการ import เข้ามาให้สามารถใช้งานได้
  },
  computed: {
    dateRangeText() {
      if (!this.dates[0] && !this.dates[1]) {
        // this.initDateFilter();
      }
      return this.dates.join("~");
    },
    isUserLevel() {
      let ul = this.isLevel();
      return ul == 'super'?true:false
    }
  },
  mounted() {
    console.log("this Bank components is running");
    this.loadData();
  },
  methods: {
    async getData(do_search){
      if (do_search) {
        this.date_period = this.dates.join("&");
      } else {
        // reset data
        this.date_period = '';
        this.dates = ['',''];
      }
       this.getDataFromApi();
    },
    searchItems: _.debounce(function() {
      // _.debounce ใน lodash ต่างจาก settimeout อย่างไร
      // 1. debounce จะ return ค่าออกมาเป็น function  สว่น   settimeout จะ return เป็น id
      // 2. debounce ตัวอย่างการใช้เช่น เวลาพิมพ์ search ข้อมูล function ใน debounce จะทำงานก็ต่อเมื่อเราได้หยุดพิมพ์ในเวลา 1.5 วิ แต่ถ้าในเวลา 1.5 วิ มีการพิมพ์ต่อ debounce ก็จะนับถอยหลังใหม่
      //     ซึ่งจะทำให้ การยิง request ไปที่ server ลดจำนวนการยิงลงจนกว่าเราจะพิมพ์ข้อความที่ต้องการค้นหาเสร็จ และไม่มีการพิมพ์ต่อเนื่องใน 1.5 วินั้นเอง
      this.loadData();
    }, 1000),
    loadData() {
      this.getDataFromApi();
    },
    async getDataFromApi() {
      this.dataTableLoading = true;
      this.dataTableLoading2 = true;
       let resultTransfer = api.getBankReportTransfer(this.date_period);
       let resultCOD = api.getBankReportCOD(this.date_period);

       let dataTransfer = await resultTransfer;
       let dataCOD = await resultCOD;

       this.mDataArray = dataTransfer.data;
       this.mDataCOD = dataCOD.data;
       this.dataTableLoading = false;
       this.dataTableLoading2 = false;
    },
    switchActive: _.debounce(async function(item) {
      this.dataTableLoading = true;
      let { id, active } = item;
      let formData = new FormData();
      formData.append("id", id);
      formData.append("active", active ? 1 : 0);
      await api.updateBankToActive(formData);
      this.dataTableLoading = false;
    }, 700),
    checkPointCOD(amount, count) {
        if (count > BANKLIMIT.CODCOUNT && amount > BANKLIMIT.TRANSFERAMOUNT) {
          console.log(count, amount)
            return "red--text"
        } else {
            return '';
        }
    },
    checkPointTransfer(amount, count) {
        if (amount > BANKLIMIT.TRANSFERAMOUNT || count > BANKLIMIT.TRANSFERCOUNT) {
            return "red--text";
        } else {
            return '';
        }
    },
    checkPointTransferCount(count) {
        if (count > BANKLIMIT.TRANSFERCOUNT) {
            return "red--text";
        } else {
            return '';
        }
    },
    checkPointTransferAmount(amount) {
        if (amount > BANKLIMIT.TRANSFERAMOUNT) {
            return "red--text";
        } else {
            return '';
        }
    }
  },
  beforeDestroy: function() {
    delete this.mDataArray;
    delete this.mDataCOD;
  }
};
</script>

<style></style>
